<template>
  <span class="relative inline-block">
    <img
      class="object-cover text-xs rounded-full"
      :class="sizeClasses"
      :src="member.photo_url"
    />
  </span>
</template>
<script>
export default {
  name: 'member-avatar',

  props: {
    member: {
      type: Object,
      required: true
    },

    variableSize: {
      type: Number,
      default: 10
    }
  },

  computed: {
    sizeClasses() {
      return `w-${this.variableSize} h-${this.variableSize}`
    }
  }
}
</script>
